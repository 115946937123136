import React, { useState, useEffect } from "react";
import useDelayUnmount from "../lib/useDelayUnmount";
import Lottie from "react-lottie-player";
import { cn } from "../lib/helpers";
import { splashScreenAnimationDuration } from "../lib/transition-config";

import styles from "./splash-screen.module.css";

const SplashScreen = (props) => {
  const { splashLottieFile, callback } = props;
  const [animationData, setAnimationData] = useState(null);
  const [isMounted, setIsMounted] = useState(true);
  const shouldRenderChild = useDelayUnmount(isMounted, splashScreenAnimationDuration);

  const defaultOptions = {
    loop: false,
    play: true,
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(splashLottieFile.asset.url);
      let responseJson = await response.json();
      setAnimationData(responseJson);
    };
    fetchData();
  }, []);

  if (shouldRenderChild) {
    return (
      <div className={cn(styles.root, isMounted ? styles.animateIn : styles.animateOut)}>
        <div className={cn(styles.animationWrapper)}>
          {animationData && (
            <Lottie
              {...defaultOptions}
              animationData={animationData}
              onComplete={() => {
                setIsMounted(false);
                callback();
              }}
            />
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};
export default SplashScreen;
