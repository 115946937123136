import React, { useState, useEffect } from "react";
import QHomePage from "../queries/QHomePage";

import { connect } from "react-redux";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import HomePage from "../components/home-page";
import SplashScreen from "../components/splash-screen";
import { toggleSplashscreen } from "../actions";
import { splashScreenAnimationDuration } from "../lib/transition-config";

const IndexPage = (props) => {
  const { errors, showSplashScreen, toggleSplashscreen } = props;
  const [hideHomepage, setHideHomepage] = useState(true);

  const splashScreenCallback = () => {
    setHideHomepage(false);
    setTimeout(() => {
      toggleSplashscreen(false);
    }, splashScreenAnimationDuration);
  };

  useEffect(() => {
    if (showSplashScreen == false) {
      setHideHomepage(false);
    }
  }, []);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <QHomePage>
      {({ site, homepage }) => {
        return (
          <>
            {site.enableSplashPage && showSplashScreen && (
              <SplashScreen
                splashLottieFile={site.splashLottieFile}
                callback={splashScreenCallback}
              />
            )}
            <Layout hidePage={hideHomepage && site.enableSplashPage}>
              <SEO title={site.title} description={site.description} keywords={site.keywords} />
              <HomePage {...homepage} splashPageEnabled={site.enableSplashPage} />
            </Layout>
          </>
        );
      }}
    </QHomePage>
  );
};
const mapStateToProps = (state) => {
  return { showSplashScreen: state.showSplashScreen };
};
export default connect(mapStateToProps, { toggleSplashscreen })(IndexPage);
